import { changeLoginStatus, loginStatus, userInfo } from "@/Utils/hook/hook";
import { bus } from "@/Utils/bus";
import MessageService from "@/plugins/message-plugin";
// 全局管理 WebSocket 实例
let websocket = null;
let shouldReconnect = true; // 是否允许重连的标志
class HeartbeatWebSocket {
    static instance = null;
    ws = null;
    initialHeartbeatInterval;
    currentHeartbeatInterval;
    heartbeatTimer = null;
    reconnectInterval;
    reconnectTimer = null;
    isConnected = false;
    url;
    reconnectAttempts = 0;
    constructor(url, heartbeatInterval = 3000, reconnectInterval = 5000) {
        this.url = url;
        this.initialHeartbeatInterval = heartbeatInterval;
        this.currentHeartbeatInterval = heartbeatInterval;
        this.reconnectInterval = reconnectInterval;
        this.connect();
    }
    static getInstance(url) {
        if (!HeartbeatWebSocket.instance) {
            HeartbeatWebSocket.instance = new HeartbeatWebSocket(url);
        }
        return HeartbeatWebSocket.instance;
    }
    send(message) {
        if (this.isConnected && this.ws) {
            this.ws.send(message);
        }
        else {
            this.isConnected = false;
            this.stopHeartbeat();
            this.attemptReconnect();
            MessageService.error("Network fluctuation, please try again later");
            console.error("WebSocket is not connected. Cannot send message:", message);
        }
    }
    close() {
        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }
        this.stopHeartbeat();
        if (this.reconnectTimer !== null) {
            clearTimeout(this.reconnectTimer);
        }
        websocket = null;
        HeartbeatWebSocket.instance = null; // 释放单例
    }
    onMessage(e) {
        try {
            JSON.parse(e.data);
        }
        catch (err) {
            return;
        }
        const msg = JSON.parse(e.data);
        console.log('onMessage.msg.code999999', msg);
        if (msg.code === 1) {
            this.resetHeartbeatInterval();
            return;
        }
        switch (msg.type) {
            case 200:
                console.log("收到新信息 通知");
                bus.emit("getNewsNotify");
                break;
            case 5:
                console.log("收到vip新信息");
                bus.emit("getVipunReadNumber", msg.data);
                break;
            case 10:
            case 12:
                if (!msg.data)
                    return;
                bus.emit("getNewChatMessage", msg);
                break;
            case 2:
                bus.emit("getOnlineUserNumber", JSON.parse(msg.data.userCount));
                break;
            case 3:
                bus.emit("changeUserAmount", msg.data);
                break;
            case 303:
                changeLoginStatus(false, "init");
                break;
            case 202:
                MessageService.success(msg.data.content);
                break;
        }
    }
    connect() {
        if (this.ws) {
            this.ws.close();
        }
        this.ws = new WebSocket(this.url);
        this.ws.onopen = () => {
            this.isConnected = true;
            this.reconnectAttempts = 0;
            this.currentHeartbeatInterval = this.initialHeartbeatInterval;
            this.startHeartbeat();
        };
        this.ws.onmessage = (event) => {
            if (event.data === "{\"type\":0}") {
                this.resetHeartbeatInterval();
            }
            else {
                this.onMessage(event);
            }
        };
        this.ws.onclose = (event) => {
            console.log("WebSocket 断开连接");
            if (event.code === 1000) {
                console.log('WebSocket连接正常关闭:', event.code);
            }
            else if (event.code === 1001) {
                console.log('WebSocket连接被客户端关闭', event.code);
            }
            else if (event.code === 1006) {
                console.log('WebSocket连接关闭异常', event.code);
            }
            else {
                console.log('WebSocket连接关闭，状态码：', event.code);
            }
            this.isConnected = false;
            this.stopHeartbeat();
            if (shouldReconnect) {
                this.attemptReconnect();
            }
        };
        this.ws.onerror = (error) => {
            console.error("WebSocket 错误:", error);
            this.ws?.close();
        };
    }
    startHeartbeat() {
        this.stopHeartbeat();
        this.heartbeatTimer = setInterval(() => {
            this.send(JSON.stringify({ type: 0 }));
        }, this.currentHeartbeatInterval);
    }
    stopHeartbeat() {
        if (this.heartbeatTimer !== null) {
            clearInterval(this.heartbeatTimer);
            this.heartbeatTimer = null;
        }
    }
    resetHeartbeatInterval() {
        this.currentHeartbeatInterval = this.initialHeartbeatInterval;
    }
    attemptReconnect() {
        if (this.reconnectTimer !== null) {
            clearTimeout(this.reconnectTimer);
        }
        this.reconnectTimer = setTimeout(() => {
            this.reconnectAttempts += 1;
            console.log(`尝试重连 次数:${this.reconnectAttempts}`);
            this.connect();
        }, this.reconnectInterval);
    }
}
// 手动断开连接
function disconnectWebSocket() {
    shouldReconnect = false; // 阻止自动重连
    if (websocket) {
        console.log('手动断开 WebSocket 连接');
        websocket.close(); // 关闭当前连接
    }
}
// 手动重连
function reconnectWebSocket() {
    shouldReconnect = true; // 允许重连
    initWebSocket();
}
const initWebSocket = () => {
    if (!websocket) {
        if (process.env.NODE_ENV === 'development')
            return;
        websocket = HeartbeatWebSocket.getInstance(`wss://${window.location.host}/chat`);
        // websocket = HeartbeatWebSocket.getInstance(`ws://localhost:9988/chat`);
        // websocket = new HeartbeatWebSocket('ws://172.19.25.4:8080/chat');
    }
};
const sendMsg = (data) => {
    try {
        console.log('发送成功参数为', data);
        websocket?.send(JSON.stringify(data));
    }
    catch (e) {
        MessageService.error("发送失败，请刷新后重试");
        console.error("WebSocket 发送失败:", e);
    }
};
const sendChatMsg = (str) => {
    console.log('使用ws并传参为', str);
    if (!loginStatus.value) {
        bus.emit("openGlobalDialog", { type: "Login" });
        return;
    }
    sendMsg({
        type: 10,
        data: {
            text: str,
            from: userInfo.value.id,
            fromName: userInfo.value.userName,
            imgUrl: userInfo.value.imgUrl,
        },
    });
};
// const websocketclose = () => {
//     websocket?.close();
//     websocket = null;
// };
initWebSocket();
export { initWebSocket, sendChatMsg, disconnectWebSocket, reconnectWebSocket };
