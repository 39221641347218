export default {
    main: {
        login: 'Prijavi se',
        signup: 'Registruj se',
        language: 'Jezik',
        toTop: 'Na vrh',
        licence: 'Licenca',
        profile: 'Lični profil',
        nodata: 'Nema podataka',
        logout: 'Odjavi se',
        notify: 'Obaveštenja',
        all: 'Sve',
        system: 'Sistemsko obaveštenje',
        activity: 'Aktivnosti',
        clear: 'Obriši sve',
        more: 'Učitaj još',
        wallet: 'Novčanik',
        deposit: 'Depozit',
        withdraw: 'Povlačenje',
        trans: 'Transakcije',
        currency: 'Depozitna valuta',
        network: 'Mreža',
        address: 'Adresa za depozit',
        mini: 'Minimalni depozit',
        send: 'Pošaljite samo USDT na ovu adresu za depozit. Novčići će biti automatski deponovani nakon 6 potvrda na mreži. Pametne ugovorne adrese nisu podržane (Kontaktirajte nas).',
        withAddress: 'Adresa za povlačenje',
        withEnter: 'Unesite svoju adresu',
        amount: 'Iznos za povlačenje',
        security: 'Iz sigurnosnih razloga, velike ili sumnjive transakcije povlačenja mogu potrajati od 1 do 6 sati za proces revizije. Hvala na strpljenju!',
        today: 'Danas',
        week: 'Ove nedelje',
        month: 'Ovaj mesec',
        year: 'Ove godine',
        myWallet: 'Moj novčanik',
        available: 'Dostupno',
        fee: 'Naknada',
        chat: 'Ćaskanje',
        online: 'Na mreži',
        message: 'Unesite poruku',
        rules: 'Pravila'
    },
    login: {
        email: "Molimo unesite e-poštu",
        incorrect: "Format e-pošte je neispravan",
        password: "Molimo unesite lozinku",
        characters: "Lozinka treba da bude duga između 6 i 15 brojeva ili slova.",
        account: "Nalog",
        login: "Prijavi se",
        register: "Registruj se",
        userEmail: "Vaša e-pošta",
        enterEmail: "Unesite vašu e-poštu",
        userPas: "Vaša lozinka",
        enterPas: "Unesite vašu lozinku",
        forget: "Zaboravili ste lozinku?",
        or: "ili",
        agree: "Pročitao/la sam i slažem se sa Uslovima i Pravilima.",
        resAndLogin: "Registruj se i prijavi se",
        policy: "Ovaj sajt je zaštićen reCAPTCHA-om, a na njega se primenjuju Google-ova Pravila o privatnosti i Uslovi korišćenja."
    },
    profile: {
        password: "Molimo unesite novu lozinku",
        long: "Lozinka mora sadržati najmanje jednu cifru, slovo ili razmak i imati između 6 i 15 karaktera",
        newPas: "Potvrđena lozinka se ne podudara sa novom lozinkom",
        original: "Molimo unesite originalnu lozinku",
        personal: 'Lična podešavanja',
        change: 'Promeni nadimak i svoje podatke',
        username: 'Vaše korisničko ime',
        hide: 'Sakrij moj nadimak',
        private: 'Privatni profil',
        closing: 'Zatvaranje vašeg naloga',
        closeDatail: 'Možete zatvoriti svoj nalog klikom na dugme "Zatvori moj nalog" ispod. Svi vaši podaci će biti trajno obrisani bez mogućnosti budućeg oporavka (uključujući sve VIP beneficije).',
        closeMy: 'Zatvori moj nalog',
        social: 'Društvene mreže',
        media: 'Poveži društvene mreže',
        connected: 'Povezano',
        noConnect: 'nije povezano',
        settings: 'Podešavanja',
        account: 'Opšta podešavanja vašeg naloga',
        email: 'E-mail',
        verify: 'Molimo vas da verifikujete svoj e-mail',
        factor: 'Dvofaktorska autentifikacija',
        enable: 'Omogućite dvofaktorsku autentifikaciju kako biste zaštitili svoj nalog od neovlašćenog pristupa.',
        prohibit: 'Zabraniti',
        pas: 'Lozinka',
        current: 'Vaša trenutna lozinka',
        please: 'Unesite svoju trenutnu lozinku',
        newPassword: 'Nova lozinka',
        paleseNew: 'Unesite svoju novu lozinku',
        repeat: 'Ponovite lozinku',
        pleaseCurrent: 'Unesite svoju trenutnu lozinku',
        changePas: 'Promeni lozinku',
        sessions: 'Sesije',
        devices: 'Proverite svoje uređaje',
        history: 'Istorija igara',
        referrals: 'Preporuke',
        VIP: 'VIP Program',
        check: 'Proverite sve svoje transakcije',
        allTrans: 'Sve transakcije',
        withdrawal: 'Povlačenja',
        timeframe: 'Vremenski okvir',
        today: 'Danas',
        deposits: 'Depoziti',
        chackGame: 'Proverite sve svoje igre',
        chain: 'Lanac'
    },
    components: {
        search: "Pretraga",
        sort: "Sortiraj po:",
        popular: "Popularno",
        providers: "Provajderi:",
        new: "Novo",
        hot: "Vruće",
        notice: "Još nema obaveštenja.",
        enable: "Omogući",
        cybet: "Cybet.com je zaštitni znak Raw Entertainment B.V., broj registracije 157205, registrovan na adresi Korporaalweg 10, Curacao, sa licencom za online igre koju je izdao vlada Curacaua, broj licence 8048/JAZ. Raw Entertainment Ltd, broj registracije HE421735, registrovan na adresi Voukourestiou, 25, Neptune House, prvi sprat, stan/ured 11, Zakaki, 3045, Limassol, Kipar, član je Cybet grupe i deluje kao agent za plaćanje i operacije u ime Raw Entertainment B.V. (18+).",
        progress: "Vaš VIP napredak",
        discover: "Otkrijte krajnje iskustvo igranja sa ekskluzivnim VIP sistemom CY.GAMES!",
        link: "Vaš link",
        share: "Zaradite $1,000.00 za svakog prijatelja kojeg pozovete, plus do 25% provizije na njihove opklade. Uživajte u doslednim provizijama, bez obzira da li pobeđuju ili gube, u našem Kazinu i Sportskoj kladionici. Počnite da zarađujete sada!",
        referralLink: "Vaš referral link",
        code: "Vaš referral kod",
        yourReferrals: "Svi vaši referali",
        receive: "Primi",
        game: "Sve igre",
        winnings: "Dobici",
        loosing: "Gubici",
        wagered: "Vaši igrači su uložili",
        earned: "Zaradili ste",
        nothing: "Još uvek nema ništa za naplatu",
        join: "Pridružite se našoj zajednici elitnih igrača i uživajte u najboljem što online igranje može da ponudi.",
        details: "Detalji",
        unverified: "nepotvrđeno",
        verified: "E-mail je potvrđen",
        type: "Tip transakcije",
        date: "Datum",
        total: "Ukupno uloženo",
        methods: "Metode plaćanja",
        status: "Status",
        device: "Uređaj",
        location: "Lokacija",
        IP: "IP adresa",
        last: "Poslednja upotreba",
        tableGame: "Igra",
        bet: "Opklada",
        multiplayer: "Više igrača",
        profit: "Profit",
        searchGame: "Pretraži igru",
        provider: "Provajderi",
        ourGames: "Naše igre",
        allWins: "Sve pobede",
        luckyWins: "Srećne pobede",
        bigWins: "Velike pobede",
        latest: "Poslednje opklade",
        player: "IME IGRAČA",
        time: "VREME",
        amount: "IZNOS",
        coefficent: "KOEFICIJENT"
    },
    wallet: {
        noAdress: 'Adresa ne može biti prazna',
        submit: 'Zahtev za isplatu je podnet, čekajte potvrdu...',
        range: 'Molimo unesite iznos unutar opsega isplate'
    }
};
