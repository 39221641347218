import { createApp, h, ref } from 'vue';
import Message from '@/components/GlobalMessage/messageIndex.vue';
import { deviceAdvanced } from "@/Utils/hook/hook";
class MessageService {
    container;
    messageQueue = [];
    messageCount = 0;
    messageRefs = [];
    constructor() {
        this.container = document.createElement('div');
        this.container.classList.add('cybet-message-container');
        document.body.appendChild(this.container);
    }
    updateMessagePositions() {
        this.messageRefs.forEach((ref, index) => {
            const top = deviceAdvanced.value === 'mobile' ? 6 : 82 + 15 + (index * 82);
            ref.top = top;
            ref.element.style.top = `${top}px`;
        });
    }
    showMessage() {
        if (this.messageQueue.length === 0)
            return;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const { message, type, img, title } = this.messageQueue.shift();
        const messageElement = document.createElement('div');
        this.container.appendChild(messageElement);
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        const top = ref(deviceAdvanced.value === 'mobile' ? 6 : 82 + 15 + (this.messageCount * 82));
        const app = createApp({
            render() {
                return h(Message, {
                    message,
                    type,
                    img,
                    title,
                    top: top.value,
                    onClose: () => {
                        app.unmount();
                        that.container.removeChild(messageElement);
                        const index = that.messageRefs.findIndex(ref => ref.element === messageElement);
                        if (index !== -1) {
                            that.messageRefs.splice(index, 1);
                        }
                        that.messageCount--;
                        that.updateMessagePositions();
                        that.showMessage();
                    },
                });
            }
        });
        this.messageCount++;
        this.messageRefs.push({ top: top.value, element: messageElement });
        app.mount(messageElement);
    }
    success(message, title, img) {
        this.messageQueue.push({ message, type: 'success', title: title || '', img });
        this.showMessage();
    }
    error(message, title, img) {
        this.messageQueue.push({ message, type: 'error', title: title || '', img });
        this.showMessage();
    }
    warning(message, title, img) {
        this.messageQueue.push({ message, type: 'warning', title: title || '', img });
        this.showMessage();
    }
}
export default new MessageService();
//引用的时候直接用MessageService.success(message,title,img)，第二个和第三个非必填，img是动态图标
