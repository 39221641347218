export default {
    main: {
        login: 'Kirjaudu sisään',
        signup: 'Rekisteröidy',
        language: 'Kieli',
        toTop: 'Ylös',
        licence: 'Lisenssi',
        profile: 'Henkilökohtainen profiili',
        nodata: 'Ei tietoja',
        logout: 'Kirjaudu ulos',
        notify: 'Ilmoitukset',
        all: 'Kaikki',
        system: 'Järjestelmäilmoitus',
        activity: 'Toiminnot',
        clear: 'Tyhjennä kaikki',
        more: 'Lataa lisää',
        wallet: 'Lompakko',
        deposit: 'Talletus',
        withdraw: 'Nosto',
        trans: 'Tapahtumat',
        currency: 'Talletusvaluutta',
        network: 'Verkko',
        address: 'Talletusosoite',
        mini: 'Minimitalletus',
        send: 'Lähetä vain USDT tähän talletusosoitteeseen. Kolikot talletetaan automaattisesti 6 verkon vahvistuksen jälkeen. Älykkäät sopimusosoitteet eivät ole tuettuja (Ota yhteyttä).',
        withAddress: 'Nosto-osoite',
        withEnter: 'Syötä osoitteesi',
        amount: 'Nostomäärä',
        security: 'Turvallisuussyistä suurten tai epäilyttävien nostojen tarkistus voi kestää 1–6 tuntia. Kiitos kärsivällisyydestäsi!',
        today: 'Tänään',
        week: 'Tällä viikolla',
        month: 'Tässä kuussa',
        year: 'Tänä vuonna',
        myWallet: 'Oma lompakkoni',
        available: 'Saatavilla',
        fee: 'Maksu',
        chat: 'Chat',
        online: 'Verkossa',
        message: 'Kirjoita viesti',
        rules: 'Säännöt'
    },
    login: {
        email: "Syötä sähköposti",
        incorrect: "Sähköpostin muoto on virheellinen",
        password: "Syötä salasana",
        characters: "Salasana on 6-15 numeroa tai kirjainta pitkä.",
        account: "Tili",
        login: "Kirjaudu sisään",
        register: "Rekisteröidy",
        userEmail: "Sähköpostisi",
        enterEmail: "Syötä sähköpostisi",
        userPas: "Salasanasi",
        enterPas: "Syötä salasanasi",
        forget: "Unohtuiko salasana?",
        or: "tai",
        agree: "Olen lukenut ja hyväksyn käyttöehdot.",
        resAndLogin: "Rekisteröidy ja kirjaudu sisään",
        policy: "Tämä sivusto on suojattu reCAPTCHA:lla, ja Google:n tietosuojakäytäntö ja käyttöehdot ovat voimassa."
    },
    profile: {
        password: "Syötä uusi salasana",
        long: "Salasanassa on oltava vähintään yksi numero, kirjain tai välilyönti ja sen on oltava 6-15 merkkiä pitkä",
        newPas: "Vahvistussalasana ei vastaa uutta salasanaa",
        original: "Syötä alkuperäinen salasana",
        personal: 'Henkilökohtaiset asetukset',
        change: 'Vaihda nimimerkki ja tietosi',
        username: 'Käyttäjänimi',
        hide: 'Piilota nimimerkkini',
        private: 'Yksityinen profiili',
        closing: 'Tilin sulkeminen',
        closeDatail: 'Voit sulkea tilisi napsauttamalla alla olevaa "Sulje tilini" -painiketta. Kaikki tietosi poistetaan pysyvästi ilman mahdollisuutta tulevaan palautukseen (mukaan lukien VIP-edut).',
        closeMy: 'Sulje tilini',
        social: 'Sosiaalinen',
        media: 'Yhdistä sosiaalinen media',
        connected: 'Yhdistetty',
        noConnect: 'ei yhdistetty',
        settings: 'Asetukset',
        account: 'Tilin yleiset asetukset',
        email: 'Sähköposti',
        verify: 'Vahvista sähköpostisi',
        factor: 'Kaksivaiheinen todennus',
        enable: 'Ota käyttöön kaksivaiheinen todennus suojataksesi tilisi luvattomalta käytöltä.',
        prohibit: 'Estä',
        pas: 'Salasana',
        current: 'Nykyinen salasana',
        please: 'Anna nykyinen salasanasi',
        newPassword: 'Uusi salasana',
        paleseNew: 'Anna uusi salasana',
        repeat: 'Toista salasana',
        pleaseCurrent: 'Anna nykyinen salasana',
        changePas: 'Vaihda salasana',
        sessions: 'Istunnot',
        devices: 'Tarkista laitteesi',
        history: 'Pelihistoria',
        referrals: 'Suosittelut',
        VIP: 'VIP-ohjelma',
        check: 'Tarkista kaikki tapahtumasi',
        allTrans: 'Kaikki tapahtumat',
        withdrawal: 'Nostot',
        timeframe: 'Aikajakso',
        today: 'Tänään',
        deposits: 'Talletukset',
        chackGame: 'Tarkista kaikki pelisi',
        chain: 'Ketju'
    },
    components: {
        search: "Hae",
        sort: "Lajittele:",
        popular: "Suosittu",
        providers: "Tarjoajat:",
        new: "Uusi",
        hot: "Kuuma",
        notice: "Ei ilmoituksia vielä.",
        enable: "Ota käyttöön",
        cybet: "Cybet.com on Raw Entertainment B.V.:n tavaramerkki, rekisterinumero 157205, rekisteröity osoitteessa Korporaalweg 10, Curacao, ja sillä on Curaçaon hallituksen myöntämä lisenssi online-pelaamiseen, lisenssinumero 8048/JAZ. Raw Entertainment Ltd, rekisterinumero HE421735, rekisteröity osoitteessa Voukourestiou, 25, Neptune House, 1. kerros, huoneisto/toimisto 11, Zakaki, 3045, Limassol, Kypros, on osa Cybet-ryhmää ja toimii maksuvälittäjänä ja operatiivisena agenttina Raw Entertainment B.V.:n puolesta. (18+).",
        progress: "VIP-edistymisesi",
        discover: "Löydä äärimmäinen pelikokemus CY.GAMES:n eksklusiivisella VIP-järjestelmällä!",
        link: "Linkkisi",
        share: "Saat 1 000,00 $ jokaisesta ystävästä, jonka kutsut, sekä jopa 25 % palkkion heidän vedoistaan. Nauti tasaisista palkkioista, voittavatko he vai häviävät, kasinossamme ja urheiluvedonlyönnissä. Aloita ansainta nyt!",
        referralLink: "Suosittelulinkkisi",
        code: "Suosittelukoodisi",
        yourReferrals: "Kaikki suosittelusi",
        receive: "Vastaanota",
        game: "Kaikki pelit",
        winnings: "Voitot",
        loosing: "Tappiot",
        wagered: "Pelaajasi ovat panostaneet",
        earned: "Olet ansainnut",
        nothing: "Ei mitään vaadittavaa vielä",
        join: "Liity eliittipelaajien yhteisöömme ja nauti parhaasta, mitä verkkopelaaminen voi tarjota.",
        details: "Tiedot",
        unverified: "vahvistamaton",
        verified: "Sähköposti vahvistettu",
        type: "Transaktion tyyppi",
        date: "Päivämäärä",
        total: "Yhteensä pelatut",
        methods: "Maksutavat",
        status: "Tila",
        device: "Laite",
        location: "Sijainti",
        IP: "IP-osoite",
        last: "Viimeksi käytetty",
        tableGame: "Peli",
        bet: "Panostus",
        multiplayer: "Moninpelit",
        profit: "Voitto",
        searchGame: "Etsi peli",
        provider: "Palveluntarjoajat",
        ourGames: "Pelit",
        allWins: "Kaikki voitot",
        luckyWins: "Onnennousut",
        bigWins: "Suuret voitot",
        latest: "Uusimmat panostukset",
        player: "Pelaajan NIMI",
        time: "AIKA",
        amount: "MÄÄRÄ",
        coefficent: "KERROIN"
    },
    wallet: {
        noAdress: 'Osoite ei voi olla tyhjö',
        submit: 'Nostopyyntö on lähetetty, odota vahvistusta...',
        range: 'Ole hyvä ja syötä summa nostorajan sisällä'
    }
};
