<template>
  <div class="loading-spinner-container">
    <div class="outer-circle"></div>
    <div class="middle-circle"></div>
    <div class="inner-circle"></div>
  </div>
</template>

<script setup>
</script>

<style scoped lang="scss">
.loading-spinner-container {
  position: relative;
  width: 90px;
  height: 90px;
  /* 外圈（大圈），顺时针旋转 */
  .outer-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 6px solid transparent; /* 中圈颜色 */
    border-top: 6px solid #20ce2e; /* 中圈颜色 */
    border-left: 6px solid #20ce2e; /* 中圈颜色 */
    border-radius: 50%;
    animation: spin 2s linear infinite;
    animation-direction: normal; /* 顺时针旋转 */
  }

  /* 中圈，逆时针旋转 */
  .middle-circle {
    position: absolute;
    top: 15%;
    left: 15%;
    width: 70%;
    height: 70%;
    border: 6px solid transparent; /* 中圈颜色 */
    border-top: 6px solid #20ce2e; /* 中圈颜色 */
    border-right: 6px solid #20ce2e; /* 外圈颜色 */
    border-radius: 50%;
    animation: spin 1.5s linear infinite;
    animation-direction: normal; /* 逆时针旋转 */
  }
  /* 内圈（小圈），顺时针旋转 */
  .inner-circle {
    position: absolute;
    top: 30%;
    left: 30%;
    width: 40%;
    height: 40%;
    border: 6px solid transparent; /* 中圈颜色 */
    border-top: 6px solid #20ce2e; /* 小圈颜色 */
    border-left: 6px solid #20ce2e; /* 小圈颜色 */
    border-radius: 50%;
    animation: spin 1s linear infinite;
    animation-direction: normal; /* 顺时针旋转 */
  }
  /* 旋转动画 */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
