import { createRouter, createWebHashHistory } from "vue-router";
import { loginStatus } from "@/Utils/hook/hook";
import { bus } from "@/Utils/bus";
const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: () => import('@/Views/HomePage/HomeIndex.vue'),
        children: [
            {
                path: '/',
                name: 'index',
                component: () => import('@/Views/MainPage/MainIndex.vue')
                // component: () => import('@/Views/Policy/IndexPage.vue')
            },
            {
                path: '/policy',
                name: 'policy',
                component: () => import('@/Views/Policy/IndexPage.vue')
            },
            {
                path: '/TestIndex',
                name: 'TestIndex',
                component: () => import('@/Views/Test/TestIndex.vue'),
                meta: {
                    notKeepAlive: true
                }
            },
            {
                path: '/playGame',
                name: 'playGame',
                component: () => import('@/Views/gameDetail/playGame.vue'),
                meta: {
                    needLogin: true
                }
            },
            {
                path: '/profile',
                name: 'profile',
                component: () => import('@/Views/Profile/indexPage.vue'),
                meta: {
                    needLogin: true,
                }
            }, {
                path: '/SearchGame/:gameTag?/:provider?/:gameType?',
                name: 'SearchGame',
                component: () => import('@/components/SearchGame/SearchGame.vue'),
                meta: {
                    notKeepAlive: true
                }
            }, {
                path: '/Overview',
                name: 'drop',
                component: () => import('@/Views/Overview/OverViewIndex.vue'),
            },
            {
                path: '/VipBonus',
                name: 'VipBonus',
                component: () => import('@/Views/VipBonus/VipBonus.vue'),
                meta: {
                    needLogin: true,
                    notKeepAlive: true
                }
            },
            {
                path: '/CyGame/:gameCode',
                name: 'CyGame',
                component: () => import('@/Views/CyGame/CyGame.vue'),
                props: true,
                meta: {
                    needLogin: true,
                    notKeepAlive: true
                }
            },
            {
                path: '/game',
                meta: {
                    needLogin: true,
                    notKeepAlive: true
                },
                children: [
                    {
                        path: '/game/10001',
                        name: 'CrashGame',
                        component: () => import('@/Views/Game/CrashGame/CrashIndex.vue'),
                    },
                    {
                        path: '/game/10002',
                        name: 'PlinkoGame',
                        component: () => import('@/Views/Game/PlinkoGame/PlinkoIndex.vue'),
                    },
                    {
                        path: '/game/10005',
                        name: 'MinesIndex',
                        component: () => import('@/Views/Game/MinesGame/MinesIndex.vue'),
                    },
                    {
                        path: '/game/10003',
                        name: 'MinesIndex',
                        component: () => import('@/Views/Game/DiceGame/DiceIndex.vue'),
                    },
                    {
                        path: '/game/10004',
                        name: 'LimboIndex',
                        component: () => import('@/Views/Game/LimboGame/LimboIndex.vue'),
                    },
                ]
            },
        ]
    },
];
const router = createRouter({
    history: createWebHashHistory(),
    routes,
    strict: true,
    // 添加这一段解决问题
    scrollBehavior(to, from, savedPosition) {
        // 如果 `savedPosition` 存在，则表示这是通过浏览器的前进/后退按钮导航，此时返回保存的位置
        console.log('savedPosition', savedPosition);
        if (savedPosition) {
            return savedPosition;
        }
        else {
            // 否则返回顶部
            return { top: 0 };
        }
    }
});
router.beforeEach(async (to, from, next) => {
    if (to.path === '/game') {
        if (to.query.channel === 'HS-GAME') {
            if (!loginStatus.value && to.meta.needLogin) {
                bus.emit("openGlobalDialog", { type: "Login" });
                next(from.fullPath);
                return;
            }
            else {
                window.postMessage(JSON.stringify({ type: "destroyGame" }), "*");
                // 确保使用适当的类型来定义路径
                const gameCode = String(to.query.gameCode);
                next({
                    name: 'Game', // 使用路由的名称
                    params: { gameCode } // 将 gameCode 作为 params 传递
                });
                return;
            }
        }
        console.log('进入游戏页面---- 保存url');
    }
    else {
        window.postMessage(JSON.stringify({ type: "destroyGame" }), "*");
        if (window.unloadScripts) {
            window.unloadScripts();
        }
        if (!loginStatus.value && to.meta.needLogin) {
            bus.emit("openGlobalDialog", { type: "Login" });
            next({
                path: '/',
                query: { redirect: to.fullPath }
            });
            return;
        }
        next();
        return;
    }
    next();
});
export default router;
