import { createVNode, render } from 'vue';
import LoadingComponent from '@/components/LoadingComponent/IndexPage.vue';  // 你的 loading 组件


const loadingDirective = {
    mounted(el, binding) {
        const loadingComponent = createVNode(LoadingComponent);
        let loading = false
        if (typeof binding.value === 'boolean') {
            // 支持 v-cyLoading="true"
            if (binding.value) {
                loading = true
            } else {
                loading = false
            }
        } else {
            // 支持v-cyLoading="{ loading: true }"
            if (binding.value?.loading) {

                loading = true
            } else {

                loading = false
            }
        }
        // 创建一个 container div 存放 loading 组件
        const container = document.createElement('div');
        el.insertBefore(container, el.firstChild);  // 将 loading 组件放在第一个子节点之前
        // 设置容器的宽高为 100% 以充满父级元素
        container.style = 'position:absolute;top:0;left:0;right:0;bottom:0;width:100%;height:100%;z-index:200;display:flex;justify-content:center;align-items:center;'
        // 设置父级容器的定位（如果未设置）
        if (getComputedStyle(el).position === 'static') {
            el.style.position = 'relative';
        }
        // 控制 loading 显示或隐藏
        if (loading) {
            render(loadingComponent, container);
            let width = binding.value?.width || '90px'
            let height = binding.value?.height || '90px'
            let borderWidth = binding.value?.borderWidth || '6px'
            let loadingBox = loadingComponent.el
            if (loadingBox) {
                loadingBox.style.width = width
                loadingBox.style.height = height
                Array.from(loadingBox.children).forEach((item) => {
                    item.style.borderWidth = borderWidth
                })
            }
            let background = binding.value?.background || '#0000004f'
            let borderRadius = binding.value?.borderRadius || '0px'
            container.style.borderRadius = borderRadius
            container.style.background = background
        }
        // 保存 container 引用
        el.__loadingContainer = container;
        el.__loadingComponent = loadingComponent;
    },

    updated(el, binding) {
        if (el.__loadingContainer) {
            let loading = false
            if (typeof binding.value === 'boolean') {
                if (binding.value) {
                    loading = true
                } else {
                    loading = false
                }
            } else {
                if (binding.value?.loading) {
                    loading = true
                } else {

                    loading = false
                }
            }
            // 控制 loading 显示或隐藏
            if (loading) {
                if (!el.__loadingContainer.hasChildNodes()) {
                    render(el.__loadingComponent, el.__loadingContainer); // 渲染 loading 组件
                }
            } else {
                render(null, el.__loadingContainer); // 如果没有 loading，移除 loading 组件
                el.__loadingContainer.remove(); // 从 DOM 中移除 loading 容器
                delete el.__loadingContainer; // 清理引用
            }
        }
    },
    unmounted(el) {
        if (el.__loadingContainer) {
            render(null, el.__loadingContainer); // 移除 loading 组件
            el.__loadingContainer.remove(); // 从 DOM 中删除
        }
    },
};

export default loadingDirective;
