export default {
    main: {
        login: 'Giriş yap',
        signup: 'Kayıt ol',
        language: 'Dil',
        toTop: 'Yukarı çık',
        licence: 'Lisans',
        profile: 'Kişisel Profil',
        nodata: 'Veri yok',
        logout: 'Çıkış yap',
        notify: 'Bildirimler',
        all: 'Tümü',
        system: 'Sistem bildirimi',
        activity: 'Etkinlikler',
        clear: 'Tümünü temizle',
        more: 'Daha fazla yükle',
        wallet: 'Cüzdan',
        deposit: 'Para yatırma',
        withdraw: 'Para çekme',
        trans: 'İşlemler',
        currency: 'Para birimi yatırma',
        network: 'Ağ',
        address: 'Para yatırma adresi',
        mini: 'Minimum para yatırma',
        send: 'Sadece bu para yatırma adresine USDT gönderin. Paralar, 6 ağ onayından sonra otomatik olarak yatırılacaktır. Akıllı sözleşme adresleri desteklenmez (Bizimle iletişime geçin).',
        withAddress: 'Para çekme adresi',
        withEnter: 'Adresinizi girin',
        amount: 'Para çekme tutarı',
        security: 'Güvenlik nedeniyle, büyük veya şüpheli para çekme işlemleri, denetim süreci için 1-6 saat sürebilir. Sabırlı olduğunuz için teşekkür ederiz!',
        today: 'Bugün',
        week: 'Bu hafta',
        month: 'Bu ay',
        year: 'Bu yıl',
        myWallet: 'Cüzdanım',
        available: 'Mevcut',
        fee: 'Ücret',
        chat: 'Sohbet',
        online: 'Çevrimiçi',
        message: 'Mesaj girin',
        rules: 'Kurallar'
    },
    login: {
        email: "Lütfen e-posta girin",
        incorrect: "E-posta formatı yanlış",
        password: "Lütfen şifre girin",
        characters: "Şifre, 6 ile 15 arasında rakam veya harf uzunluğunda olmalıdır.",
        account: "Hesap",
        login: "Giriş yap",
        register: "Kayıt ol",
        userEmail: "E-posta adresiniz",
        enterEmail: "E-posta adresinizi girin",
        userPas: "Şifreniz",
        enterPas: "Şifrenizi girin",
        forget: "Şifrenizi mi unuttunuz?",
        or: "veya",
        agree: "Şartlar ve Koşulları okudum ve kabul ediyorum.",
        resAndLogin: "Kayıt ol ve giriş yap",
        policy: "Bu site, reCAPTCHA ile korunmaktadır ve Google'ın Gizlilik Politikası ve Hizmet Şartları geçerlidir."
    },
    profile: {
        password: "Lütfen yeni bir şifre girin",
        long: "Şifre en az bir rakam, harf veya boşluk içermeli ve 6-15 karakter uzunluğunda olmalıdır",
        newPas: "Onay şifresi yeni şifreyle eşleşmiyor",
        original: "Lütfen orijinal şifreyi girin",
        personal: 'Kişisel Ayarlar',
        change: 'Takma adınızı ve verilerinizi değiştirin',
        username: 'Kullanıcı Adınız',
        hide: 'Takma adımı gizle',
        private: 'Gizli profil',
        closing: 'Hesabınızı kapatma',
        closeDatail: 'Aşağıdaki "Hesabımı Kapat" düğmesine tıklayarak hesabınızı kapatabilirsiniz. Tüm verileriniz kalıcı olarak silinecek ve gelecekte geri alınma imkânı olmayacaktır (VIP avantajları dahil).',
        closeMy: 'Hesabımı kapat',
        social: 'Sosyal',
        media: 'Sosyal medyayı bağla',
        connected: 'Bağlı',
        noConnect: 'bağlı değil',
        settings: 'Ayarlar',
        account: 'Hesabınızın genel ayarları',
        email: 'E-posta',
        verify: 'Lütfen e-postanızı doğrulayın',
        factor: 'İki faktörlü kimlik doğrulama',
        enable: 'Hesabınızı yetkisiz erişimlerden korumak için iki faktörlü kimlik doğrulamayı etkinleştirin.',
        prohibit: 'Yasakla',
        pas: 'Şifre',
        current: 'Mevcut şifreniz',
        please: 'Lütfen mevcut şifrenizi girin',
        newPassword: 'Yeni şifre',
        paleseNew: 'Lütfen yeni şifrenizi girin',
        repeat: 'Şifreyi tekrar girin',
        pleaseCurrent: 'Lütfen mevcut şifrenizi girin',
        changePas: 'Şifreyi değiştir',
        sessions: 'Oturumlar',
        devices: 'Cihazlarınızı kontrol edin',
        history: 'Oyun geçmişi',
        referrals: 'Yönlendirmeler',
        VIP: 'VIP Programı',
        check: 'Tüm işlemlerinizi kontrol edin',
        allTrans: 'Tüm işlemler',
        withdrawal: 'Çekimler',
        timeframe: 'Zaman çerçevesi',
        today: 'Bugün',
        deposits: 'Para yatırma',
        chackGame: 'Tüm oyunlarınızı kontrol edin',
        chain: 'Zincir'
    },
    components: {
        search: "Ara",
        sort: "Sırala:",
        popular: "Popüler",
        providers: "Sağlayıcılar:",
        new: "Yeni",
        hot: "Sıcak",
        notice: "Henüz bildirim yok.",
        enable: "Etkinleştir",
        cybet: "Cybet.com, Raw Entertainment B.V.'nin tescilli markasıdır, kayıt numarası 157205, Korporaalweg 10, Curacao adresinde kayıtlıdır, Curacao hükümeti tarafından çevrimiçi oyun için lisanslıdır, lisans numarası 8048/JAZ. Raw Entertainment Ltd, kayıt numarası HE421735, Voukourestiou, 25, Neptune House, 1. Kat, Daire/Ofis 11, Zakaki, 3045, Limassol, Kıbrıs adresinde kayıtlıdır, Cybet grubunun bir üyesidir ve Raw Entertainment B.V. adına ödeme ve operasyonel ajan olarak görev yapmaktadır. (18+).",
        progress: "VIP İlerlemeniz",
        discover: "CY.GAMES'in özel VIP sistemi ile nihai oyun deneyimini keşfedin!",
        link: "Bağlantınız",
        share: "Davet ettiğiniz her arkadaşınız için 1.000,00 $ alın ve bahislerinden %25'e kadar komisyon kazanın. Kazanıp kaybetmelerine bakılmaksızın, Casino ve Sportsbook'umuzda düzenli komisyonların tadını çıkarın. Hemen kazanmaya başlayın!",
        referralLink: "Tavsiye bağlantınız",
        code: "Tavsiye kodunuz",
        yourReferrals: "Tüm tavsiyeleriniz",
        receive: "Al",
        game: "Tüm oyunlar",
        winnings: "Kazançlar",
        loosing: "Kayıplar",
        wagered: "Oyuncularınızın bahis miktarı",
        earned: "Kazandığınız miktar",
        nothing: "Henüz talep edilecek bir şey yok",
        join: "Elit oyuncular topluluğumuza katılın ve çevrimiçi oyunların en iyisinin keyfini çıkarın.",
        details: "Ayrıntılar",
        unverified: "doğrulanmadı",
        verified: "E-posta doğrulandı",
        type: "İşlem türü",
        date: "Tarih",
        total: "Toplam bahis",
        methods: "Ödeme yöntemleri",
        status: "Durum",
        device: "Cihaz",
        location: "Konum",
        IP: "IP Adresi",
        last: "Son kullanım",
        tableGame: "Oyun",
        bet: "Bahis",
        multiplayer: "Çok oyunculu",
        profit: "Kar",
        searchGame: "Oyun ara",
        provider: "Sağlayıcılar",
        ourGames: "Oyunlarımız",
        allWins: "Tüm kazançlar",
        luckyWins: "Şanslı kazançlar",
        bigWins: "Büyük kazançlar",
        latest: "Son bahisler",
        player: "OYUNCU ADI",
        time: "ZAMAN",
        amount: "MİKTAR",
        coefficent: "KATSAYI"
    },
    wallet: {
        noAdress: 'Adres boş bırakılamaz',
        submit: 'Çekim talebi gönderildi, onay bekleniyor...',
        range: 'Lütfen çekim aralığında bir miktar girin'
    }
};
