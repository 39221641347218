import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router/index';
import '@/assets/style/index.scss';
import '@/assets/style/font.scss';
import i18n from '@/locales/i18n';
import { bus } from '@/Utils/bus';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/dark/css-vars.css';
import 'element-plus/theme-chalk/display.css'; //基于断点的隐藏
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import "@/assets/style/common.scss";
import "@/assets/style/skin.scss";
import "@/assets/style/skinV2.scss";
import "@/assets/style/numerical.scss";
import "@/assets/style/Mobile/mobile.scss";
import "@/assets/style/Mobile/pc.scss";
import "@/assets/style/Mobile/lg.scss";
import useSoundDirective from "@/directives/useSoundDirective";
// import vue3GoogleLogin from 'vue3-google-login'
import BaseButton from '@/components/BaseButton/BaseButton.vue';
import MaskDialog from '@/components/Dialog/MaskDialog.vue';
import loadingDirective from '@/directives/loading.js'; // 导入自定义指令
// import {setRootFontSize} from '@/Utils/setRootFontSize';
// import MessageService from "./plugins/message-plugin";
// import myPlugin from "./plugins/message-plugin";
// setRootFontSize();
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
//注册全局 i18n转换函数
app.config.globalProperties.$t = (val) => {
    return i18n.global.t(val);
};
//注册全局bus
app.config.globalProperties.$bus = bus;
// 注册全局指令
app.directive('cyLoading', loadingDirective);
// 使用方法v-cyLoading="isLoading"或者设置转圈条宽度和高度粗细等属性如v-cyLoading="{ loading: isLoading, width: '60px', height: '50px',border:'3px' }"
app.component('BaseButton', BaseButton);
app.component('MaskDialog', MaskDialog);
// app.use(MessageService)
app.use(router);
app.use(i18n);
app.use(ElementPlus);
app.use(useSoundDirective);
// app.use(vue3GoogleLogin, {
//     clientId: '403667147708-4ec0m24gt2745d2f3d01mnj2mm83khmj.apps.googleusercontent.com'
//   })
app.directive('throttle', {
    beforeMount(el, binding) {
        // 存储定时器
        let isThrottled = false;
        // 点击事件处理程序
        const throttledClick = (event) => {
            if (!isThrottled) {
                isThrottled = true; // 设置为节流状态
                binding.value(event); // 调用绑定的方法
                // 1000毫秒后重置节流状态
                setTimeout(() => {
                    isThrottled = false;
                }, 1000);
            }
        };
        // 添加事件监听器
        el.addEventListener('click', throttledClick);
        // 将处理函数存储到元素上以便于在卸载时移除
        el['throttledClick'] = throttledClick;
    },
    unmounted(el) {
        // 移除事件监听器
        el.removeEventListener('click', el['throttledClick']);
    }
});
app.mount('#app');
