export default {
    main: {
        login: '로그인',
        signup: '가입하기',
        language: '언어',
        toTop: '위로 가기',
        licence: '라이센스',
        profile: '개인 프로필',
        nodata: '데이터 없음',
        logout: '로그아웃',
        notify: '알림',
        all: '모두',
        system: '시스템 알림',
        activity: '활동',
        clear: '모두 지우기',
        more: '더 불러오기',
        wallet: '지갑',
        deposit: '입금',
        withdraw: '출금',
        trans: '거래 내역',
        currency: '입금 화폐',
        network: '네트워크',
        address: '입금 주소',
        mini: '최소 입금액',
        send: '이 입금 주소로 USDT만 보내세요. 코인은 네트워크 6회 확인 후 자동으로 입금됩니다. 스마트 계약 주소는 지원되지 않습니다(문의 바랍니다).',
        withAddress: '출금 주소',
        withEnter: '주소를 입력하세요',
        amount: '출금 금액',
        security: '보안상 이유로, 대규모 또는 의심스러운 출금은 심사에 1~6시간이 소요될 수 있습니다. 양해 부탁드립니다!',
        today: '오늘',
        week: '이번 주',
        month: '이번 달',
        year: '올해',
        myWallet: '내 지갑',
        available: '사용 가능',
        fee: '수수료',
        chat: '채팅',
        online: '온라인',
        message: '메시지 입력',
        rules: '규칙'
    },
    login: {
        email: "이메일을 입력하세요",
        incorrect: "이메일 형식이 올바르지 않습니다",
        password: "비밀번호를 입력하세요",
        characters: "비밀번호는 길이가 6에서 15자 사이의 숫자 또는 문자여야 합니다.",
        account: "계정",
        login: "로그인",
        register: "등록",
        userEmail: "이메일",
        enterEmail: "이메일을 입력하세요",
        userPas: "비밀번호",
        enterPas: "비밀번호를 입력하세요",
        forget: "비밀번호를 잊으셨나요?",
        or: "또는",
        agree: "저는 이용 약관을 읽고 동의합니다.",
        resAndLogin: "회원가입 및 로그인",
        policy: "이 사이트는 reCAPTCHA로 보호되며 Google의 개인정보 보호정책 및 서비스 약관이 적용됩니다."
    },
    profile: {
        password: "새 비밀번호를 입력하세요",
        long: "비밀번호는 적어도 하나의 숫자, 문자 또는 공백을 포함해야 하며 길이는 6~15자 사이여야 합니다",
        newPas: "확인 비밀번호가 새 비밀번호와 일치하지 않습니다",
        original: "기존 비밀번호를 입력하세요",
        personal: '개인 설정',
        change: '닉네임 및 데이터 변경',
        username: '사용자 이름',
        hide: '내 닉네임 숨기기',
        private: '비공개 프로필',
        closing: '계정 종료',
        closeDatail: '아래 "내 계정 닫기" 버튼을 클릭하여 계정을 종료할 수 있습니다. 모든 데이터는 영구적으로 삭제되며 향후 복구할 수 없습니다 (VIP 혜택 포함).',
        closeMy: '내 계정 닫기',
        social: '소셜',
        media: '소셜 미디어 연결',
        connected: '연결됨',
        noConnect: '연결되지 않음',
        settings: '설정',
        account: '계정 일반 설정',
        email: '이메일',
        verify: '이메일을 확인해 주세요',
        factor: '이중 인증',
        enable: '계정을 보호하기 위해 이중 인증을 활성화하세요.',
        prohibit: '금지',
        pas: '비밀번호',
        current: '현재 비밀번호',
        please: '현재 비밀번호를 입력해 주세요',
        newPassword: '새 비밀번호',
        paleseNew: '새 비밀번호를 입력해 주세요',
        repeat: '비밀번호 재입력',
        pleaseCurrent: '현재 비밀번호를 입력해 주세요',
        changePas: '비밀번호 변경',
        sessions: '세션',
        devices: '기기 확인',
        history: '게임 기록',
        referrals: '추천',
        VIP: 'VIP 프로그램',
        check: '모든 거래 확인',
        allTrans: '모든 거래',
        withdrawal: '출금',
        timeframe: '기간',
        today: '오늘',
        deposits: '입금',
        chackGame: '모든 게임 확인',
        chain: '체인'
    },
    components: {
        search: "검색",
        sort: "정렬 기준:",
        popular: "인기",
        providers: "제공자:",
        new: "새로운",
        hot: "핫",
        notice: "아직 공지가 없습니다.",
        enable: "활성화",
        cybet: "Cybet.com은 Raw Entertainment B.V.의 상표로, 등록 번호 157205, Korporaalweg 10, Curacao에 등록되어 있으며, Curacao 정부로부터 온라인 게임 라이센스를 보유하고 있습니다. 라이센스 번호 8048/JAZ. Raw Entertainment Ltd, 등록 번호 HE421735, Voukourestiou 25, Neptune House, 1층, Flat/Office 11, Zakaki, 3045, Limassol, Cyprus에 등록되어 있으며, Cybet 그룹의 일원으로 Raw Entertainment B.V.를 대신하여 결제 및 운영 대행 역할을 합니다. (18+).",
        progress: "당신의 VIP 진행 상황",
        discover: "CY.GAMES의 독점 VIP 시스템으로 궁극의 게임 경험을 발견하세요!",
        link: "당신의 링크",
        share: "친구를 초대할 때마다 $1,000.00를 받고, 그들의 베팅에 대해 최대 25%의 수수료를 받으세요. 카지노와 스포츠북에서 그들이 이기든 지든 안정적인 수수료를 즐기세요. 지금 바로 시작하세요!",
        referralLink: "당신의 추천 링크",
        code: "당신의 추천 코드",
        yourReferrals: "당신의 모든 추천",
        receive: "받기",
        game: "모든 게임",
        winnings: "당첨금",
        loosing: "손실",
        wagered: "당신의 플레이어들이 베팅한 금액",
        earned: "당신이 번 금액",
        nothing: "아직 받을 것이 없습니다",
        join: "우리 엘리트 플레이어 커뮤니티에 가입하고 온라인 게임의 최고의 혜택을 누리세요.",
        details: "세부 사항",
        unverified: "인증되지 않음",
        verified: "이메일 인증됨",
        type: "거래 유형",
        date: "날짜",
        total: "총 베팅액",
        methods: "지불 방법",
        status: "상태",
        device: "장치",
        location: "위치",
        IP: "IP 주소",
        last: "마지막 사용",
        tableGame: "게임",
        bet: "베팅",
        multiplayer: "멀티플레이어",
        profit: "수익",
        searchGame: "게임 검색",
        provider: "제공자",
        ourGames: "우리의 게임",
        allWins: "모든 승리",
        luckyWins: "행운의 승리",
        bigWins: "대승리",
        latest: "최신 베팅",
        player: "플레이어 이름",
        time: "시간",
        amount: "금액",
        coefficent: "계수"
    },
    wallet: {
        noAdress: '주소를 비워둘 수 없습니다',
        submit: '출금 요청이 제출되었습니다. 확인 중입니다...',
        range: '출금 범위 내 금액을 입력하세요'
    }
};
