import { soundState } from '@/Utils/hook/GameHook';
export default {
    install(app) {
        app.directive("sound", {
            mounted(el, binding) {
                const soundUrl = binding.value || require("@/assets/sounds/click.mp3");
                const audio = new Audio(soundUrl);
                const playSound = () => {
                    if (soundState.isClickSoundEnabled) {
                        audio.currentTime = 0;
                        audio
                            .play()
                            .catch((err) => console.error("Audio playback failed:", err));
                    }
                };
                el.addEventListener("click", playSound);
                // 解绑时移除事件监听
                el._cleanup = () => el.removeEventListener("click", playSound);
            },
            unmounted(el) {
                el._cleanup && el._cleanup();
            },
        });
    },
};
