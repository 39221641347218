export default {
    main: {
        login: 'Masuk',
        signup: 'Daftar',
        language: 'Bahasa',
        toTop: 'Ke atas',
        licence: 'Lisensi',
        profile: 'Profil Pribadi',
        nodata: 'Tidak Ada Data',
        logout: 'Keluar',
        notify: 'Notifikasi',
        all: 'Semua',
        system: 'Pemberitahuan sistem',
        activity: 'Aktivitas',
        clear: 'Hapus semua',
        more: 'Muat lebih banyak',
        wallet: 'Dompet',
        deposit: 'Setoran',
        withdraw: 'Penarikan',
        trans: 'Transaksi',
        currency: 'Mata uang setoran',
        network: 'Jaringan',
        address: 'Alamat setoran',
        mini: 'Setoran minimum',
        send: 'Kirim hanya USDT ke alamat setoran ini. Koin akan secara otomatis disetor setelah 6 konfirmasi jaringan. Alamat kontrak pintar tidak didukung (Hubungi kami).',
        withAddress: 'Alamat penarikan',
        withEnter: 'Masukkan alamat Anda',
        amount: 'Jumlah penarikan',
        security: 'Untuk alasan keamanan, penarikan besar atau mencurigakan mungkin memerlukan waktu 1-6 jam untuk proses audit. Kami menghargai kesabaran Anda!',
        today: 'Hari ini',
        week: 'Minggu ini',
        month: 'Bulan ini',
        year: 'Tahun ini',
        myWallet: 'Dompet saya',
        available: 'Tersedia',
        fee: 'Biaya',
        chat: 'Obrolan',
        online: 'Online',
        message: 'Masukkan pesan',
        rules: 'Aturan'
    },
    login: {
        email: "Silakan masukkan email",
        incorrect: "Format email salah",
        password: "Silakan masukkan kata sandi",
        characters: "Kata sandi memiliki panjang antara 6 hingga 15 angka atau huruf.",
        account: "Akun",
        login: "Masuk",
        register: "Daftar",
        userEmail: "Email Anda",
        enterEmail: "Masukkan email Anda",
        userPas: "Kata sandi Anda",
        enterPas: "Masukkan kata sandi Anda",
        forget: "Lupa kata sandi?",
        or: "atau",
        agree: "Saya telah membaca dan menyetujui Syarat & Ketentuan.",
        resAndLogin: "Daftar dan Masuk",
        policy: "Situs ini dilindungi oleh reCAPTCHA dan Kebijakan Privasi serta Syarat Layanan Google berlaku."
    },
    profile: {
        password: "Silakan masukkan kata sandi baru",
        long: "Kata sandi harus mengandung setidaknya satu angka, huruf, atau spasi dan panjangnya antara 6-15 karakter",
        newPas: "Kata sandi konfirmasi tidak cocok dengan kata sandi baru",
        original: "Silakan masukkan kata sandi asli",
        personal: 'Pengaturan Pribadi',
        change: 'Ubah nama panggilan dan data Anda',
        username: 'Nama Pengguna Anda',
        hide: 'Sembunyikan nama panggilan saya',
        private: 'Profil pribadi',
        closing: 'Menutup akun Anda',
        closeDatail: 'Anda dapat menutup akun Anda dengan mengklik tombol "Tutup Akun Saya" di bawah ini. Semua data Anda akan dihapus secara permanen tanpa kemungkinan pemulihan di masa depan (termasuk manfaat VIP apa pun).',
        closeMy: 'Tutup akun saya',
        social: 'Sosial',
        media: 'Hubungkan media sosial',
        connected: 'Terkoneksi',
        noConnect: 'Tidak terhubung',
        settings: 'Pengaturan',
        account: 'Pengaturan umum akun Anda',
        email: 'E-mail',
        verify: 'Silakan verifikasi email Anda',
        factor: 'Otentikasi dua faktor',
        enable: 'Aktifkan otentikasi dua faktor untuk melindungi akun Anda dari akses yang tidak sah.',
        prohibit: 'Melarang',
        pas: 'Kata sandi',
        current: 'Kata sandi Anda saat ini',
        please: 'Silakan masukkan kata sandi Anda saat ini',
        newPassword: 'Kata sandi baru',
        paleseNew: 'Silakan masukkan kata sandi baru Anda',
        repeat: 'Ulangi kata sandi',
        pleaseCurrent: 'Silakan masukkan kata sandi Anda saat ini',
        changePas: 'Ganti kata sandi',
        sessions: 'Sesi',
        devices: 'Periksa perangkat Anda',
        history: 'Riwayat permainan',
        referrals: 'Referensi',
        VIP: 'Program VIP',
        check: 'Periksa semua transaksi Anda',
        allTrans: 'Semua transaksi',
        withdrawal: 'Penarikan',
        timeframe: 'Jangka waktu',
        today: 'Hari ini',
        deposits: 'Setoran',
        chackGame: 'Periksa semua permainan Anda',
        chain: 'Rantai'
    },
    components: {
        search: "Cari",
        sort: "Urutkan Berdasarkan:",
        popular: "Populer",
        providers: "Penyedia:",
        new: "Baru",
        hot: "Terhangat",
        notice: "Belum ada pemberitahuan.",
        enable: "Aktifkan",
        cybet: "Cybet.com adalah merek dagang dari Raw Entertainment B.V., nomor registrasi 157205, terdaftar di Korporaalweg 10, Curacao, berlisensi untuk permainan daring oleh pemerintah Curacao, nomor lisensi 8048/JAZ. Raw Entertainment Ltd, nomor registrasi HE421735, terdaftar di Voukourestiou, 25, Neptune House, Lantai 1, Flat/Kantor 11, Zakaki, 3045, Limassol, Siprus, adalah anggota grup Cybet dan bertindak sebagai agen pembayaran dan operasional atas nama Raw Entertainment B.V. (18+).",
        progress: "Kemajuan VIP Anda",
        discover: "Temukan pengalaman bermain game terbaik dengan sistem VIP eksklusif dari CY.GAMES!",
        link: "Tautan Anda",
        share: "Dapatkan $1,000.00 untuk setiap teman yang Anda undang, ditambah komisi hingga 25% dari taruhan mereka. Nikmati komisi stabil, baik mereka menang atau kalah, di Kasino dan Sportsbook kami. Mulai menghasilkan sekarang!",
        referralLink: "Tautan referral Anda",
        code: "Kode referral Anda",
        yourReferrals: "Semua referral Anda",
        receive: "Terima",
        game: "Semua game",
        winnings: "Kemenangan",
        loosing: "Kerugian",
        wagered: "Pemain Anda telah bertaruh",
        earned: "Anda telah menghasilkan",
        nothing: "Belum ada yang bisa diklaim",
        join: "Bergabunglah dengan komunitas pemain elit kami dan nikmati yang terbaik dari permainan online.",
        details: "Detail",
        unverified: "belum diverifikasi",
        verified: "Email telah diverifikasi",
        type: "Jenis transaksi",
        date: "Tanggal",
        total: "Total taruhan",
        methods: "Metode pembayaran",
        status: "Status",
        device: "Perangkat",
        location: "Lokasi",
        IP: "Alamat IP",
        last: "Terakhir digunakan",
        tableGame: "Permainan",
        bet: "Taruhan",
        multiplayer: "Multiplayer",
        profit: "Keuntungan",
        searchGame: "Cari permainan",
        provider: "Penyedia",
        ourGames: "Permainan kami",
        allWins: "Semua kemenangan",
        luckyWins: "Kemenangan beruntung",
        bigWins: "Kemenangan besar",
        latest: "Taruhan terbaru",
        player: "NAMA PEMAIN",
        time: "WAKTU",
        amount: "JUMLAH",
        coefficent: "KOEFISIEN"
    },
    wallet: {
        noAddress: 'Alamat tidak boleh kosong',
        submit: 'Permintaan penarikan telah diajukan, menunggu konfirmasi...',
        range: 'Silakan masukkan jumlah numerik dalam rentang penarikan'
    }
};
